import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid, { GridSize } from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';

const mock: {
  title: string;
  description: string;
  price: string;
  note?: string;
  features: { title: string; isIncluded: boolean; hasCheckIcon: boolean }[];
  isHighlighted: boolean;
  btnText: string;
  md: GridSize;
}[] = [
  {
    title: 'Pay-as-you-go',
    description: "Don't pay for what you don't use",
    price: '0',
    note: '1GB = ~1 hour of video',
    features: [
      {
        title:
          'We start you off with $50 of credits to work with your first videos ($0.15 per hour of video). This gives you powerful features like fast footage retrieval, browser-based video editing and collaboration, and live bookmarking, as well as access to valuable add-ons.',
        isIncluded: true,
        hasCheckIcon: false,
      },
    ],
    isHighlighted: true,
    btnText: '',
    md: 4,
  },
  {
    title: 'Save with a subscription',
    description: 'Great for regular video editing',
    price: '0',
    features: [
      {
        title:
          "If you use Catapult frequently, you can subscribe for as little as $10/month, and, as an added bonus, we'll record your streams automatically, so you can get down to work whenever you are ready.",
        isIncluded: true,
        hasCheckIcon: false,
      },
      // {
      //   title: 'Automatic retrieval of every stream',
      //   isIncluded: true,
      //   hasCheckIcon: true,
      // },
    ],
    isHighlighted: true,
    btnText: '',
    md: 4,
  },
  {
    title: 'Valuable add-ons',
    description: 'All the tools you need for great videos',
    price: '0',
    features: [
      {
        title:
          'Working with a video in Catapult unlocks access to an ever-growing number of valuable add-on services, such as transcription, translation, and even advanced video editing from our professional services team.',
        isIncluded: true,
        hasCheckIcon: false,
      },
      // {
      //   title: 'Machine transcription ($0.05/minute)',
      //   isIncluded: true,
      //   hasCheckIcon: true,
      // },
      // {
      //   title: 'Translation ($1.25/minute)',
      //   isIncluded: true,
      //   hasCheckIcon: true,
      // },
      // {
      //   title: '6-months extended access to your streams ($0.10/GB)',
      //   isIncluded: true,
      //   hasCheckIcon: true,
      // },
      // {
      //   title:
      //     'Professional video creation and editing services (starting at $15/video)',
      //   isIncluded: true,
      //   hasCheckIcon: true,
      // },
      // {
      //   title: 'Need something else? Contact us!',
      //   isIncluded: true,
      //   hasCheckIcon: false,
      // },
    ],
    isHighlighted: true,
    btnText: '',
    md: 4,
  },
];

const Pricing = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box maxWidth={900} margin={'0 auto'}>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
            }}
            color={'secondary'}
            align={'center'}
          >
            Plans
          </Typography>
        </Box>
        <Typography
          sx={{ fontWeight: 700 }}
          component="h3"
          variant="h4"
          align="center"
          gutterBottom
        >
          Start editing with Catapult for no upfront cost.
        </Typography>
      </Box>
      <Grid container spacing={isMd ? 0 : 2}>
        {mock.map((item, i) => (
          <Grid
            item
            container
            alignItems={'center'}
            xs={12}
            md={item.md}
            key={i}
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
          >
            <Box
              component={Card}
              display={'flex'}
              flexDirection={'column'}
              height="100%"
              border={0}
              paddingY={item.isHighlighted && isMd ? 8 : 2}
            >
              <CardContent>
                <Box
                  marginBottom={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography variant={'h6'} gutterBottom>
                    <Box component={'span'} fontWeight={600}>
                      {item.title}
                    </Box>
                  </Typography>
                  <Typography variant={'body1'} color="text.secondary">
                    {item.description}
                  </Typography>
                  {Number(item.price) !== 0 && (
                    <>
                      <Box display={'flex'} alignItems={'flex-start'}>
                        <Typography variant={'h4'} color={'primary'}>
                          <Box
                            component={'span'}
                            fontWeight={600}
                            marginRight={1 / 2}
                          ></Box>
                          $
                        </Typography>
                        <Typography
                          variant={'h3'}
                          color={'primary'}
                          gutterBottom
                        >
                          <Box component={'span'} fontWeight={600}>
                            {item.price}
                          </Box>
                        </Typography>
                      </Box>
                      <Typography
                        variant={'subtitle2'}
                        color={'text.secondary'}
                        visibility={item.note ? 'visible' : 'hidden'}
                      >
                        {item.note || 'placeholder'}
                      </Typography>
                    </>
                  )}
                </Box>
                <Grid container spacing={1}>
                  {item.features.map((feature, j) => (
                    <Grid item xs={12} key={j}>
                      <Typography
                        component={'p'}
                        align={'left'}
                        style={{
                          textDecoration: !feature.isIncluded
                            ? 'line-through'
                            : 'none',
                        }}
                      >
                        {feature.hasCheckIcon && (
                          <CheckIcon fontSize="inherit" />
                        )}{' '}
                        {feature.title}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                {item.btnText && (
                  <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button
                      size={'large'}
                      variant={item.isHighlighted ? 'contained' : 'outlined'}
                    >
                      {item.btnText}
                    </Button>
                  </CardActions>
                )}
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Pricing;
