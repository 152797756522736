import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link } from 'gatsby';

const Blog = ({ posts }): JSX.Element => {
  const theme = useTheme();

  return (
    <Box>
      {posts?.length && (
        <>
          <Box marginBottom={4}>
            <Typography
              variant="h4"
              align={'center'}
              data-aos={'fade-up'}
              gutterBottom
              sx={{
                fontWeight: 700,
                marginTop: theme.spacing(1),
              }}
            >
              Blog posts
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {posts?.map(post => (
              <Grid item xs={12} sm={6} md={4} key={post.name}>
                <Link style={{ textDecoration: 'none' }} to={post.postPath}>
                  <Box
                    component={'div'}
                    display={'block'}
                    width={1}
                    height={1}
                    title="Click to read more"
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'none',
                      transition: 'all .2s ease-in-out',
                      '&:hover': {
                        transform: `translateY(-${theme.spacing(1 / 2)})`,
                      },
                    }}
                  >
                    <Box
                      component={Card}
                      variant="outlined"
                      width={1}
                      height={1}
                      borderRadius={2}
                      boxShadow={4}
                      display={'flex'}
                      flexDirection={'column'}
                      sx={{ backgroundImage: 'none' }}
                    >
                      <Box component={CardContent} position={'relative'}>
                        <Typography variant={'h6'} gutterBottom>
                          {post.name}
                        </Typography>
                        <Typography color="text.secondary">
                          {post.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default Blog;
