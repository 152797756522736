import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
// import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

const REVIEWERS: {
  highlight: string;
  feedback: JSX.Element;
  name: string;
  title: string;
  imgSrc: string;
  imgAlt: string;
  imgTitle: string;
}[] = [
  {
    highlight:
      'Streamers: Post quality highlight videos, not just single clips, from any stream',
    feedback: (
      <>
        "Super easy to use and <b>SO helpful</b> to someone starting out making
        video content for YT/IG. It's made jumping into content creation not so
        daunting.""
      </>
    ),
    name: '@blinkblinkgames',
    title: 'Twitch Streamer',
    imgSrc: '/images/blinkblinkgames.jpg',
    imgAlt: 'Reviewer avatar of @blinkblinkgames',
    imgTitle: 'Reviewer avatar of @blinkblinkgames',
  },
  {
    highlight: 'YouTubers: Make shorts from your original video content',
    feedback: (
      <>
        "It's so easy to take clips and place them on other parts of social
        media, sort of a fishing mechanism to <b>drive more traffic</b> to the
        main video."
      </>
    ),
    name: 'Eddy Gonzalez',
    title: 'YouTuber, Restraurant Owner',
    imgSrc: '/images/eddy_live.jpg',
    imgAlt: 'Reviewer avatar of Eddy Gonzalez',
    imgTitle: 'Reviewer avatar of Eddy Gonzalez',
  },
  {
    highlight:
      'Educators / Community Leaders: Share stories to strengthen and expand communities',
    feedback: (
      <>
        "I love working with Catapult and almost feel like they are my{' '}
        <b>secret weapon</b>."
      </>
    ),
    name: 'Atina Tan',
    title: 'Food and scuba advocate',
    imgSrc: '/images/atina_tan.jpg',
    imgAlt: 'Reviewer avatar of Atina Tan',
    imgTitle: 'Reviewer avatar of Atina Tan',
  },
];

const Reviews = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Box display={'flex'} justifyContent={'center'}>
          {[1, 2, 3, 4, 5].map(item => (
            <Box key={item} color={theme.palette.secondary.main}>
              <svg
                width={18}
                height={18}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            </Box>
          ))}
        </Box>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
            marginTop: theme.spacing(1),
          }}
        >
          Rated 5 out of 5 stars by our customers!
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          Content makers across the globe have had fantastic experiences using
          Catapult.
          <br />
          Here's what they have to say.
        </Typography>
        {/* <Box marginTop={2} display={'flex'} justifyContent={'center'}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={
              <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
          >
            View all
          </Button>
        </Box> */}
      </Box>
      <Grid container spacing={2}>
        {REVIEWERS.map((reviewer, i) => (
          <Grid item xs={12} md={4} key={reviewer.name}>
            <Box
              width={1}
              height={1}
              data-aos="fade-up"
              data-aos-delay={i * 100}
              component={Card}
              display="flex"
              flexDirection="column"
              alignItems="center"
              boxShadow={0}
              variant="outlined"
              borderRadius={4}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '36px 24px 16px',
                }}
              >
                <Typography
                  align="center"
                  color="text.primary"
                  fontSize="1.5rem"
                  fontWeight={900}
                  lineHeight={1.3}
                  marginBottom={4}
                >
                  {reviewer.highlight}
                </Typography>
                <Typography align="center" color="text.secondary">
                  {reviewer.feedback}
                </Typography>
              </CardContent>
              <Box flexGrow={1} />
              <CardActions sx={{ paddingBottom: 2 }}>
                <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  <ListItemAvatar>
                    <Avatar
                      src={reviewer.imgSrc}
                      alt={reviewer.imgAlt}
                      title={reviewer.imgTitle}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ margin: 0 }}
                    primary={reviewer.name}
                    secondary={reviewer.title}
                  />
                </ListItem>
              </CardActions>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Reviews;
