import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// import { Email } from '@mui/icons-material';

declare const gtag_report_conversion: () => void;
declare const twttr: any;
declare global {
  interface Window {
    lintrk: any;
    rdt: any;
    fbq: any;
  }
}

interface LeftSideProps {
  setEmail: (email: string) => void;
  openThankYou: () => void;
}

const LeftSide = ({ setEmail, openThankYou }: LeftSideProps): JSX.Element => {
  // const theme = useTheme();

  return (
    <Box
      sx={{
        textAlign: { xs: 'center', md: 'left' },
        height: { xs: 'auto', md: 1 },
      }}
    >
      <Box component="h1" marginTop={4} marginBottom={5}>
        <Typography
          variant="h3"
          component="span"
          color="text.primary"
          sx={{ fontWeight: 700 }}
        >
          Thank you 
          <br />
        </Typography>
        <Typography
          color="primary"
          component="span"
          variant="h3"
          sx={{ fontWeight: 700 }}
        >
          for your interest!
          <br />
        </Typography>
        {/* <Typography
        variant="h3"
        component="span"
        color="text.primary"
        sx={{ fontWeight: 700 }}
      >
        to claim $50 in credits.
      </Typography> */}
      </Box>
      <Box marginBottom={3}>
        <Typography variant="h5" component="p" color="text.secondary">
          We are not accepting new users at this time.
        </Typography>
      </Box>

      <Box marginBottom={3}>
        <Typography variant="h5" component="p" color="text.secondary">
          
        </Typography>
      </Box>
    </Box>
  );
};

export default LeftSide;
