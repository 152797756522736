import React from 'react';
import Box from '@mui/material/Box';

const videoWidth = 600;

const RightSide = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: { md: 'flex', xs: 'none' },
        alignItems: 'center',
        height: { xs: 'auto', md: 1 },
        marginTop: 1,
        // '& img': {
        //   objectFit: 'cover',
        // },
      }}
    >
      <iframe
        width={videoWidth}
        height={(videoWidth / 16) * 9}
        src="https://www.youtube.com/embed/u3Xbg2Gl2rI?autoplay=0&mute=1"
        title="Making videos got a lot faster and easier...."
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Box>
  );
};

export default RightSide;
